import { render } from "./thirdParty.vue?vue&type=template&id=0b8e5e50&scoped=true"
import script from "./thirdParty.vue?vue&type=script&lang=js"
export * from "./thirdParty.vue?vue&type=script&lang=js"

import "./thirdParty.vue?vue&type=style&index=0&id=0b8e5e50&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-0b8e5e50"
/* hot reload */
if (module.hot) {
  script.__hmrId = "0b8e5e50"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('0b8e5e50', script)) {
    api.reload('0b8e5e50', script)
  }
  
  module.hot.accept("./thirdParty.vue?vue&type=template&id=0b8e5e50&scoped=true", () => {
    api.rerender('0b8e5e50', render)
  })

}

script.__file = "src/views/static/about/thirdParty.vue"

export default script