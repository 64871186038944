<template>
   <div class="wrapper">
<!--       第三方渠道数据-->
     <img src="https://static.yihu365.cn/img/h5Img/assets/img/logo.png" alt="" class="logo">
      <p class="sub">医护到家 您身边的护理专家</p>
      <p class="sub-2"><span>ID: {{detail.sid}}</span></p>
      <p class="sub-2"> <span>当前爱心值：{{detail.currentLoveValue}} </span></p>
      <p class="sub-2"> <span>历史爱心值：{{detail.totalLoveValue}} </span></p>
      <div class="t-box">
          <table class="pure-table">
            <thead>
                  <tr>
                    <th class="date">下单日期</th>
                    <th>下单人</th>
                    <th>项目</th>
                    <th>金额(元)</th>
                    <th>手机号</th>
                    <th>爱心值</th>
                  </tr>
            </thead>
            <tbody>
             <tr class="pure-table-odd" v-for="(item,index) in detail.detailList">
               <td class="date">{{item.createDateStr}}</td>
               <td>{{item.createUserName}}</td>
               <td>{{item.productName}}</td>
               <td>{{item.orderPrice}}</td>
               <td>{{item.mobilePhone}}</td>
               <td>{{item.loveValue}}</td>
             </tr>
             </tbody>
          </table>
      </div>
       <div class="cont">
         <p>在线客服微信：yihudaojia123</p>
         <p>人工客服电话：400-800-6996</p>
       </div>
   </div>
</template>

<script>
import {reactive, ref} from 'vue';
import {useStore} from 'vuex'
import homeApi from '@axios/home'
import {useRoute, useRouter} from "vue-router";
import {Notify} from 'vant';
export default {
  name: "thirdParty",
  setup () {
    const detail = ref('')
    const route = useRoute();

    let params= {
      jumpMark: route.query.jumpMark ? route.query.jumpMark : '',
    }
    homeApi.getThirdPartyData(params).then((res)=> {
       detail.value = res.data;


    })
    return {
      detail
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
   p,table{margin:0;padding:0;}
   .wrapper{
     font-size: 30px;
     color:#606266;
     box-sizing: border-box;
     padding:34px;
      .logo{
        width: 250px;
      }

   }
   table {
     border-collapse: collapse;
     border-spacing: 0;

   }

   td,th {
     padding: 0;
   }
   tr{
     border-bottom: 1px solid #cbcbcb;
   }
   .t-box{
     width: 100%;
     border:1px solid #cbcbcb;
     margin-top:20px;
     min-height: 400px;
     max-height: 800px;
     overflow-x: scroll;
     overflow-y: scroll;
   }
   .pure-table {
     border-collapse: collapse;
     border-spacing: 0;
     //border: 1px solid #cbcbcb;
     overflow-x: scroll;

   }

   .pure-table caption {
     color: #606266;
     text-align: center;
   }

   .pure-table td,.pure-table th {
     border-left: 1px solid #cbcbcb;
     border-width: 0 0 0 1px;
     margin: 0;
     font-weight: normal;
     min-width:140px;
     padding:5px 8px;

   }
   .pure-table .date,.pure-table .date{
     min-width:180px;
   }

   .pure-table td:first-child,.pure-table th:first-child{
     border-left:none!important;
   }
   .pure-table thead {
     //background-color: #F2FDF8;
     background-color: #E9F5FF;
     color: #606266;
     text-align: left;
     font-weight: normal;
     vertical-align: bottom;
   }

   .pure-table td {
     background-color: transparent;
   }

   .pure-table-odd td {
     background-color: #F2FDF8;
   }
   .sub{
      margin: 20px 0;
      font-size: 34px;
     font-weight: 500;
   }
   .sub-2{
      //margin-bottom: 10px;
     span{
        margin-right: 40px;
     }
   }
   .cont{
      margin-top: 30px;
      text-align: center;
      font-size:30px;
      color:#606266;
   }
</style>
